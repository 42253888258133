import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './injury-type.types';
import { AlertService } from 'app/core/service/alert/alert.service';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';

@Injectable({
    providedIn: 'root'
})
export class InjuryTypeDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _injuryTypeService: EventConfigurationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._injuryTypeService.getInjuryTypesInitial()
        .pipe(
            map((alerts) => ({
                alerts
            })
            ),
            catchError(err => {
                this._injuryTypeService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                return of(new DefaultResponse().injuryTypes);
            })
        );
        
    }

}
class DefaultResponse implements InitialData {
    injuryTypes: any = [];
}