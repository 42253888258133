<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-100" src="https://salus.qbler.com/assets/img/logo/salus-logo-white.svg">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img class="w-full h-full rounded-full" style="object-fit: cover !important;" *ngIf="user.profile_image"
                    [src]="user.profile_image" alt="User avatar">
                <mat-icon class="icon-size-24" *ngIf="!user.profile_image" [svgIcon]="'heroicons_solid:user-circle'">
                </mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
                <div *ngIf="organizationName != null"
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{organizationName}}
                </div>
            </div>

            <ng-container *ngIf="allocatedStorage > 0">
                <mat-progress-bar *ngIf="showProgressbar" class="mt-4 rounded-full w-full" [mode]="'determinate'"
                    [value]="storagePercentage"
                    [color]="storagePercentage > 70 ? 'warn' : 'primary'"></mat-progress-bar>
                <div class="text-sm mt-1" *ngIf="showProgressbar">
                    Storage {{storagePercentage | number:'1.0-1'}}%
                </div>
            </ng-container>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-6 mb-4 opacity-50">
            <img class="max-w-48" src="https://salus.qbler.com/assets/img/logo/salus-logo-white.svg">
        </div> -->
        <div class="mb-6">
            <div class="flex justify-center items-center font-normal  w-full mt-15 opacity-70">
                <p style="font-size: 12px;">Crafted with Precision</p>
            </div>
            <div class="flex justify-center items-center w-full opacity-70">
                <a href="https://www.qbler.com" target="_blank" style="font-size: 12px;">QBLER</a>
            </div>
        </div>

    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
             <downloads></downloads>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div
        class="relative flex flex-0 items-center justify-start w-full h-10 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="flex justify-center items-center font-medium text-secondary w-full">
            <p style="margin-right:5px;">Made with</p>
            <img src="assets/images/love.svg" style="margin-right:5px;width:18px;height:17px;">
            <a href="https://www.qbler.com" target="_blank" style="margin-right:20px;">by QBLER</a>
        </span>
    </div> -->

</div>