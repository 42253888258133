import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar/public-api';
import { FuseHorizontalNavigationBasicItemComponent } from '@fuse/components/navigation/horizontal/components/basic/basic.component';
import { FuseHorizontalNavigationBranchItemComponent } from '@fuse/components/navigation/horizontal/components/branch/branch.component';
import { FuseHorizontalNavigationDividerItemComponent } from '@fuse/components/navigation/horizontal/components/divider/divider.component';
import { FuseHorizontalNavigationSpacerItemComponent } from '@fuse/components/navigation/horizontal/components/spacer/spacer.component';
import { FuseHorizontalNavigationComponent } from '@fuse/components/navigation/horizontal/horizontal.component';
import { FuseVerticalNavigationAsideItemComponent } from '@fuse/components/navigation/vertical/components/aside/aside.component';
import { FuseVerticalNavigationBasicItemComponent } from '@fuse/components/navigation/vertical/components/basic/basic.component';
import { FuseVerticalNavigationCollapsableItemComponent } from '@fuse/components/navigation/vertical/components/collapsable/collapsable.component';
import { FuseVerticalNavigationDividerItemComponent } from '@fuse/components/navigation/vertical/components/divider/divider.component';
import { FuseVerticalNavigationGroupItemComponent } from '@fuse/components/navigation/vertical/components/group/group.component';
import { FuseVerticalNavigationSpacerItemComponent } from '@fuse/components/navigation/vertical/components/spacer/spacer.component';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
    declarations: [
        FuseHorizontalNavigationBasicItemComponent,
        FuseHorizontalNavigationBranchItemComponent,
        FuseHorizontalNavigationDividerItemComponent,
        FuseHorizontalNavigationSpacerItemComponent,
        FuseHorizontalNavigationComponent,
        FuseVerticalNavigationAsideItemComponent,
        FuseVerticalNavigationBasicItemComponent,
        FuseVerticalNavigationCollapsableItemComponent,
        FuseVerticalNavigationDividerItemComponent,
        FuseVerticalNavigationGroupItemComponent,
        FuseVerticalNavigationSpacerItemComponent,
        FuseVerticalNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        FuseScrollbarModule
    ],
    exports: [
        FuseHorizontalNavigationComponent,
        FuseVerticalNavigationComponent
    ]
})
export class FuseNavigationModule {
    constructor(
        iconRegistry: MatIconRegistry, sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon('alert', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/alerts.svg'));
        iconRegistry.addSvgIcon('admin', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/admin_crown.svg'));
        iconRegistry.addSvgIcon('category_type', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/category_type.svg'));
        iconRegistry.addSvgIcon('checklist_classification', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/checklist_classification.svg'));
        iconRegistry.addSvgIcon('checklist', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/checklist.svg'));
        iconRegistry.addSvgIcon('classification', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/classification.svg'));
        iconRegistry.addSvgIcon('configuration', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/configuration.svg'));
        iconRegistry.addSvgIcon('dashboard', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/dashboard.svg'));
        iconRegistry.addSvgIcon('data_management', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/data.svg'));
        iconRegistry.addSvgIcon('documents', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/documents.svg'));
        iconRegistry.addSvgIcon('employees', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/employees.svg'));
        iconRegistry.addSvgIcon('factories', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/factories.svg'));
        iconRegistry.addSvgIcon('incident', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/incident.svg'));
        iconRegistry.addSvgIcon('injury_type', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/injury_type.svg'));
        iconRegistry.addSvgIcon('ncr', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/ncr.svg'));
        iconRegistry.addSvgIcon('near_miss', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/near_miss.svg'));
        iconRegistry.addSvgIcon('observation', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/observation.svg'));
        iconRegistry.addSvgIcon('organization_details', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/organization_details.svg'));
        iconRegistry.addSvgIcon('organization', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/organization.svg'));
        iconRegistry.addSvgIcon('projects', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/projects.svg'));
        iconRegistry.addSvgIcon('region', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/region.svg'));
        iconRegistry.addSvgIcon('report', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/report.svg'));
        iconRegistry.addSvgIcon('reports', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/reports.svg'));
        iconRegistry.addSvgIcon('root_cause', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/root_cause.svg'));
        iconRegistry.addSvgIcon('snag_chart', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/snag_chart.svg'));
        iconRegistry.addSvgIcon('support', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/support.svg'));
        iconRegistry.addSvgIcon('time_target', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/time_target.svg'));
        iconRegistry.addSvgIcon('report-export', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/export.svg'));
        iconRegistry.addSvgIcon('downloads', sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/download-requests.svg'));

    }
}
